<template>
  <secondary-page-layout :title="$t('confirmation')">
    <v-dialog class="error"/>
    <swipe-modal
        v-model="isModal"
        contents-height="50vh"
        border-top-radius="16px"
        :persistent="true"
        backgroundColor="#F7F7F7"
        tip-color="#c8c8c8"
        contents-color="#F7F7F7"
    >
      <sos-feedback></sos-feedback>
    </swipe-modal>
    <otp-verify  v-model="code" @submit="verifyCode" :info="info" @sendCode="sendConfirmCode" :error-message="errorMessage" @otpLength="otpLengthGet">></otp-verify>
    <otp-confirm  :info="info" @submit="sendConfirmCode" :card="cardId" :calculationData="calculationData" :otpLength="otpLength"></otp-confirm>
  </secondary-page-layout>
</template>

<script>
import { getCalculationById } from "../../api/calculations";
import {mapGetters} from "vuex";
import ePayPayment from "../../mixin/ePayPayment";
import {generateOtp, verifyOtp} from "../../api/otp";
import OtpConfirm from "../../components/OTP/OtpConfirm";
import OtpVerify from "../../components/OTP/OtpVerify";
import eventLogger from "../../services/eventLogger";
import swipeModal from "@takuma-ru/vue-swipe-modal";
import SosFeedback from "../SosFeedback";

export default {
  name: "InsuresProductsCheckout",
  mixins: [ePayPayment],
  components: {
    OtpConfirm,
    OtpVerify,
    swipeModal,
    SosFeedback
  },
  data() {
    return {
      isModal:false,
      info: {
        fullname: '',
        phone_number: '',
        start_date: '',
        bonus_product: null,
        amount: 0,
      },
      calculationData: null,
      i_agree: true,
      showVerify: false,
      code: '',
      errorMessage: null,
      paymentAuthData: null,
      otpLength: null
    }
  },
  computed: {
    ...mapGetters({authUser: 'authUser', travelerForm: 'travelerForm/finalFormValue'}),

    calculationId() {
      return this.$route.params.id || null;
    },
    cardId() {
      return this.$route.query.cardId || null;
    },
  },
  async mounted() {
    let loading = this.$loading.show();

    try {
      await this.getCalculation();
      await this.sendConfirmCode();
    } catch (e) {
      this.showErrorMessage(e?.response?.data?.message || e?.message)
    } finally {
      loading.hide()
    }
  },
  methods: {
    setInfo() {
      this.info.amount = this.calculationData.amount;
      this.info.start_date = this.calculationData.beginDate;
      this.info.phone_number = this.calculationData.phoneNumber;
      this.info.fullname = this.calculationData.holder;
      this.info.insurance_type = this.calculationData.insuranceType;
    },

    otpLengthGet(val) {
        this.otpLength = val
    },

    async getCalculation() {
      this.calculationData = await getCalculationById(this.calculationId);
      this.setInfo();
    },

    async sendConfirmCode() {
      try {
        let benefit_product;
        let period;
        let iin;

        switch (this.info.insurance_type) {
          case 2220:
          case 2221:
            benefit_product = this.$store.getters['cascoForm/bonusId'];
            period = 12;
            iin = [this.$store.getters['cascoForm/holder']?.individual_id_number];
            if(this.$store.getters['cascoForm/insureds'].length) {
              const insureds = this.$store.getters['cascoForm/insureds'].flatMap(item => item?.individual_id_number)
              iin = iin.concat(insureds)
            }
            break;
          case 2225:
            benefit_product = this.$store.getters['cascoSubs/bonusId'];
            period = 12;
            iin = [this.$store.getters['cascoSubs/holder']?.individual_id_number];
            if(this.$store.getters['cascoSubs/insureds'].length) {
              const insureds = this.$store.getters['cascoSubs/insureds'].flatMap(item => item?.individual_id_number)
              iin = iin.concat(insureds)
            }
            break;
          case 3011:
          case 3017:
            benefit_product = this.$store.getters['flatForm/bonusId'];
            period = this.$store.getters['flatForm/period'];
            iin = [this.$store.getters['flatForm/holder']?.individual_id_number];
            break;
          case 3014:
            benefit_product = this.$store.getters['flatSubs/bonusId'];
            period = this.$store.getters['flatSubs/period'];
            iin = [this.$store.getters['flatSubs/holder']?.individual_id_number];
            break;
          case 3012:
          case 3019:
            benefit_product = this.$store.getters['myHouseForm/bonusId'];
            period = this.$store.getters['myHouseForm/period'];
            iin = [this.$store.getters['myHouseForm/holder']?.individual_id_number];
            break;
          case 3016:
            benefit_product = this.$store.getters['houseSubs/bonusId'];
            period = this.$store.getters['houseSubs/period'];
            iin = [this.$store.getters['houseSubs/holder']?.individual_id_number];
            break;
          case 6230:
          case 6231:
            benefit_product = this.$store.getters['mostImportantForm/bonusId'];
            period =  this.$store.getters['mostImportantForm/period'];
            iin = [this.$store.getters['mostImportantForm/holder']?.individual_id_number];
            break;
          case 6610:
          case 6611:
            benefit_product = this.$store.getters['cheerUpForm/bonusId'];
            period =  this.$store.getters['cheerUpForm/period'];
            iin = [this.$store.getters['cheerUpForm/holder']?.individual_id_number];
            break;
          case 9100:
            iin = [this.authUser?.individual_id_number]
            break;
        }
        const loggerPayload = {
          benefit_product,
          period,
          product_type: this.info.insurance_type,
          payment_sum: this.info.amount,
          iin: iin,
          phone_number: this.info.phone_number
        }

        if(this.info.insurance_type == '2225') {
          await eventLogger('confirm_payment_kasko_subscription')
        } else if(this.info.insurance_type == '3014') {
          await eventLogger('confirm_payment_apartment_subscription')
        } else if(this.info.insurance_type == '3016') {
          await eventLogger('confirm_payment_house_subscription')
        } else {
          await eventLogger('confirm_payment', loggerPayload)
        }


        //потом верни
        await generateOtp(this.calculationId);
        //потом верни
        this.showVerify = true;
      } catch (e) {
        this.isModal = true
        await eventLogger('payment_fail')
        this.showErrorMessage(e?.response?.data?.message || e?.message)
      } finally {

      }
    },

    async verifyCode(code) {
      let loader = this.$loading.show();

      try {
        await verifyOtp(this.calculationId, code);

        this.showVerify = false;

        /** Разметка для amplitude при успешном вводе кода (пока только для подписок) */

        if(this.info.insurance_type == '2225') {
          await eventLogger('otp_success_kasko_subscription');
        } else if(this.info.insurance_type == '3014') {
          await eventLogger('otp_success_apartment_subscription');
        } else if(this.info.insurance_type == '3016') {
          await eventLogger('otp_success_house_subscription')
        }

        this.paymentAuthData = await this.getPaymentAuth(this.calculationId);

        await this.pay()
      } catch (err) {
        this.isModal = true
        const { data, status } = err.response

        if (status === 400) {
          this.errorMessage = data.message;
        }
      } finally {
        loader.hide()
      }
    },
    async pay() {
      const loader = this.$loading.show()

      if (this.cardId) {
        try{
          const { data } = await this.payWithSavedCardById(this.cardId, this.calculationData, this.authUser);
          loader.hide();
          await this.$router.push(`/insurance-products/${this.paymentAuthData.id}/payment/success?invoiceId=${data.invoiceID}`)
        }catch (err) {
          this.isModal = true
          loader.hide();
          await this.$router.push(`/insurance-products/${this.paymentAuthData.id}/payment/fail`)
        }finally {
          loader.hide();
        }
      } else {
        await this.goToPaymentPage(this.paymentAuthData, this.authUser)
        loader.hide();
      }
    },

    showErrorMessage(message) {
      this.$modal.show('dialog', {
        title: 'Ошибка',
        text: message || 'Введен неверный пароль',
        buttons: [
          {
            title: 'Закрыть',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
        ]
      });
    }
  },
}
</script>
